// consumer_trains_common_queryRailwayTimetable 获取列车时刻表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/swagger-ui.html#/base-controller/queryRailwayTimetableUsingPOST
const consumer_trains_common_queryRailwayTimetable = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/common/queryRailwayTimetable',
        // urlSuffix: 'http://s3284376h4.qicp.vip/consumer/trains/common/queryRailwayTimetable',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_common_queryRailwayTimetable;