import Transfer from '@/page/admin/company-management/staff-management/component/transfer/index.vue'
import CostAttributionList from '@/page/admin/travel-book/reserve/component/costAttributionList/index.vue'
import CheckTravel from '@/component/checkTravel/import_latest_version_module'
import ReservePopupBox from '@/page/admin/travel-book/home/component/reserve-popup-box/index.vue'
import EvectionForm from '@/component/evectionForm/import_latest_version_module'   // 因公出行信息显示组件
//返回按钮
import BackBtn from '@/component/backBtn/index.vue'
//获取火车票详情数据
import consumer_trains_order_getTrainOrderDeailByEvectionNo
  from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainOrderDeailByEvectionNo'
//价格详情
import consumer_journey_calculatePrice from '@/lib/data-service/haolv-default/consumer_journey_calculatePrice'
import consumer_trains_common_queryRailwayTimetable
  from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable'

// 选择12306人
import TrainTravelerSelector
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-traveler-selector/1.0.0/index.vue'
// 保险组件
import InsureChoice from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/insure-choice/1.0.2/index.vue'
// 创建订单组件
import TrainCreatePrivateOrder
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-create-private-order/1.0.2/index.vue';
// 选择联系人弹框
import CommonlyUsedPassenger
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/commonly-used-passenger/1.0.1/index.vue';
import TravelerMultipleSelector
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
import TravelerListForPrivateReserve
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-private-reserve/1.0.0/index.vue'
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import consumer_frequent_getLoginUserDefaultFrequents
  from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_frequent_getLoginUserDefaultFrequents";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import moment from "moment";
import TrainOrderRulePopup from '@/page/admin/travel-book/train/component/orderRulePopup/1.0.0/index.vue'

export default {
  data() {
    return {
      // 当前登录用户
      curUser: {},
      // 当前登录用户默认选中的常旅客信息
      defFrequentUser: [],
      // 出行人、联系人信息
      form: {
        userList: [],//出行人
        userIdList: [],//出行人userIdList
        linkman: '',//联系人
        linkmanPhone: '',//联系人电话
        smsType: 1,//发送行程信息到出行人手机。不发送：0；发送：1
        linkmanSms: 1//发送行程信息到联系人手机。不发送：0；发送：1
      },
      //价格信息
      priceData: {
        orderAmountOfCalculationResults: [
          {
            trainNo: '',
            seatName: ''
          }
        ],
      },
      //乘车人信息
      travelerInfo: {
        userIdList: [],
      },
      //座位信息
      trainSeatArr: [],
      //座位信息--总
      trainSeatAll: [],
      // 火车票行程列表
      trainList: {},
      // 特等座选位
      businessSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
          label: 'F',
          value: '1F',
          active: false
        }],
        [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
          label: 'F',
          value: '2F',
          active: false
        }]
      ],
      // 一等座选位
      firstSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
          label: 'D',
          value: '1D',
          active: false
        }, {label: 'F', value: '1F', active: false}],
        [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
          label: 'D',
          value: '2D',
          active: false
        }, {label: 'F', value: '2F', active: false}]
      ],
      // 二等座选位
      secondSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'B', value: '1B', active: false}, {
          label: 'C',
          value: '1C',
          active: false
        }, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
        [{label: 'A', value: '2A', active: false}, {label: 'B', value: '2B', active: false}, {
          label: 'C',
          value: '2C',
          active: false
        }, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
      ],
      //随机出票
      radioFront: '1',
      //loading效果--部分
      loading: false,
      //经停车次信息
      trainListAll: [
        {
          stationNo: '111',
          stationName: '111',
          arriveTime: '111',
          startTime: '111',
          stopTime: '111',
        }
      ],
      packageCodes: [],

      orderRuleDialogVisible: false,
    }
  },
  components: {
    TrainOrderRulePopup,
    EvectionForm,
    Transfer,
    CostAttributionList,
    CheckTravel,
    ReservePopupBox,
    TravelerMultipleSelector,
    TravelerListForPrivateReserve,
    BackBtn,
    TrainCreatePrivateOrder,
    InsureChoice,
    CommonlyUsedPassenger,
    TrainTravelerSelector,
    TrainTimetable
  },
  created() {

  },
  async mounted() {

    this.$refs.aInsureChoice.init({
      mock_data: {
        //是否使用随机数据--true/false
        enable: false,
        //使用随机数据--模拟请求时间
        wait_seconds: 2000,
      },
      //业务类型--1：机票/2：火车票
      businessType: 2,
      evectionType: 1, // 因私
      //订单号
      orderNo: this.$route.query.orderNo,
    });
    const params = {
      evectionNo: this.$route.query.evectionNo,
    };
    this.$refs['aTrainTravelerSelector'].init(params);
    await this.initCommonlyUsedPassenger();
    await this.getCurUser();
    await this.getDefaultFrequents();
    await this.setDefaultVal();
    let aTrainTimetableData = {
      fromStationCode: this.trainList.fromStationCode,
      toStationCode: this.trainList.toStationCode,
      trainCode: this.trainList.trainNo,
      trainDate: this.trainList.startDate,
    };
    this.$refs.aTrainTimetable.init(aTrainTimetableData);
  },
  async activated() {
    await this.getTrainsData();
  },
  deactivated() {

  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    filterWeek(val) {
      if (val === '') {
        return ''
      }
      let day = moment(val).day();
      if (day === 0) {
        return '周日'
      } else if (day === 1) {
        return '周一'
      } else if (day === 2) {
        return '周二'
      } else if (day === 3) {
        return '周三'
      } else if (day === 4) {
        return '周四'
      } else if (day === 5) {
        return '周五'
      } else if (day === 6) {
        return '周六'
      } else {
        return ''
      }
    },
    // 删除乘车人
    delUser(userId) {
      this.form.userList.forEach((UItem, UIndex) => {
        if (userId == UItem.userId) {
          //删除对应的用户数据
          this.form.userList.splice(UIndex, 1);
          //删除对应的用户ID
          this.form.userIdList.splice(UIndex, 1);
        }
      });
    },
    // 新增乘客--回调--录入信息
    result(item) {
      this.form.userList = item.userList;
      this.form.userIdList = item.userIdList;
      this.getAllPrice();
    },
    // 获取当前登陆用户信息
    async getCurUser() {
      let res = await get_user_info();
      this.curUser = res.datas;
    },
    //获取当前登录用户默认选中的常旅客信息
    async getDefaultFrequents() {
      let res = await consumer_frequent_getLoginUserDefaultFrequents();
      this.defFrequentUser = res.datas.frequents;
    },
    // 新增乘车人--弹框
    addUser() {
      this.$refs.aTrainTravelerSelector.toShow({
        activePassengerList: this.form.userIdList
      })
    },
    //常旅客组件--设置默认值
    setDefaultVal() {
      this.form.linkman = this.curUser.realName + '(本人)';
      this.form.linkmanPhone = this.curUser.phone;
    },
    //初始化所有常旅客组件
    initCommonlyUsedPassenger() {
      const _this = this;
      this.$refs.aCommonlyUsedPassenger.init({
        serviceType: 1,
        type: '100',
        btn_ok_click_event_handler(args) {
          //从常旅客中选中的出行人
          _this.form.userList = args.user_list.map(item => {
            let user = {
              userId: item.frequentUserId,
              userName: item.frequentPassengerName,
              operator: item.operator,
              certificateName: item.certificates.certificateTypeText,
              certificateValue: item.certificates.certificateValue
            }
            return user;
          });
          _this.form.userIdList = _this.form.userList.map(user => {
            return user.userId;
          })
        },
        btn_keep_click_event_handler(args) {
          //编辑后的旅客信息
          const editUser = args.user_list;
          _this.form.userList.forEach(item => {
            if (item.userId === editUser.frequentUserId) {
              _this.$set(item, 'userId', editUser.frequentUserId);
              _this.$set(item, 'userName', editUser.frequentPassengerName);
              _this.$set(item, 'certificateName', editUser.certificates.certificateTypeText);
              _this.$set(item, 'certificateValue', editUser.certificates.certificateValue);
            }
          })
        },
        btn_new_click_event_handler(args) {
          //新增的出行人信息
          const newUser = args.user_list;
          const user = {
            userId: newUser.frequentUserId,
            userName: newUser.frequentPassengerName,
            certificateName: newUser.certificates.certificateTypeText,
            certificateValue: newUser.certificates.certificateValue,
          }
          _this.form.userList.push(user);
          _this.form.userIdList.push(user.userId);
        },
        btn_contacts_click_event_handler(args) {
          //选中的联系人信息
          const contacts = args.user_list;
          let name = contacts.frequentPassengerName;
          if (contacts.operator === 1) {
            name += '(本人)'
          }
          _this.form.linkman = name;
          _this.form.linkmanPhone = contacts.phone;
          _this.isShowContactsPopup = false;
        },
      })
    },
    //打开选择联系人弹框
    openContactPopup() {
      this.$refs.aCommonlyUsedPassenger.showContactsPopup();
    },
    InsureCalculation() {
      let num = 0;
      if (!!this.priceData.orderAmountOfCalculationResults[0].bookingInsuranceInfos) {
        this.priceData.orderAmountOfCalculationResults[0].bookingInsuranceInfos.forEach((item, index) => {
          num = num + item.premium * this.travelerInfo.userIdList.length;
        })
      }
      return num
    },
    InsureChoiceChange(data) {
      this.$nextTick(() => {
        this.packageCodes = data.id_list;
        this.getTravelerInfo();
      })
    },
    //创建订单
    createOrder() {
      this.loading = true;
      let _this = this;
      this.travelerInfo = this.form;
      if (this.travelerInfo.userIdList.length == 0) {
        this.$message({
          type: 'warning',
          message: '请添加乘车人'
        });
        this.loading = false;
        return
      }
      if (this.travelerInfo.linkman == '' || this.travelerInfo.linkmanPhone == '') {
        this.$message({
          type: 'warning',
          message: '请输入联系人信息'
        });
        this.loading = false;
        return
      }
      // if (this.trainList.canChooseSeat == 1 && (this.trainSeatArr.length < this.travelerInfo.userIdList.length)) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请选择坐席'
      //   });
      //   this.loading = false;
      //   return
      // }
      this.packageCodes = this.$refs.aInsureChoice.get_date().id_list;
      const params = {
        mock_data: {
          enable: false,
          mode: ''
        },
        data: {
          orderNo: this.$route.query.orderNo,//订单号
          frequentUserIds: this.travelerInfo.userIdList,//出行人列表
          linkman: this.travelerInfo.linkman,//出行人列表
          linkmanPhone: this.travelerInfo.linkmanPhone,
          chooseSeats: this.trainSeatArr,
          linkmanSms: this.travelerInfo.linkmanSms,
          bookingSms: this.travelerInfo.smsType,
          packageCodes: this.packageCodes,
        },
        insuranceFun() {
          _this.$refs.aInsureChoice.submit();
        }
      };

      // 判断当前订单状态跳往不同页面--
      // (为1确认中)----(跳往占座页面继续占座)、
      // (为-1草稿箱)---(该订单没有创建过可以进行创建订单操作)、
      // (其他情况)-----(跳往占座页面无需继续占座)、
      let falseBox = false;
      let trueBox = true;
      if (this.trainList.orderStatus == 1) {
        this.loading = false;
        this.$router.replace({
          name: 'admin-holding-seat',
          query: {
            orderNo: this.$route.query.trainOrderNo,
            needPolling: 1,
            packageCodes: this.packageCodes,
          }
        })
      } else if (this.trainList.orderStatus == -1) {
        this.$refs.aTrainCreatePrivateOrder.run(params);
      } else {
        this.loading = false;
        this.$router.replace({
          name: 'admin-holding-seat',
          query: {
            orderNo: this.$route.query.trainOrderNo,
            needPolling: 0,
          }
        })
      }
    },
    handleChooseSeat(SAIItem) {
      //判断是否已选中
      if (SAIItem.active) {
        //已选中--
        this.trainSeatArr.splice(this.trainSeatArr.indexOf(SAIItem.value), 1);
        SAIItem.active = !SAIItem.active;
      } else {
        //未选中--
        if (this.travelerInfo.userIdList.length == 0) {
          this.$message({
            type: 'warning',
            message: '请先添加乘车人'
          });
          return
        }
        if (this.trainSeatArr.length >= this.travelerInfo.userIdList.length) {
          this.$message({
            type: 'warning',
            message: '请先继续添加乘车人'
          });
          return
        }
        SAIItem.active = !SAIItem.active;
        this.trainSeatArr.push(SAIItem.value);
      }
    },
    // 计算价格
    getAllPrice(){
      let data = {
        evectionNo: this.$route.query.evectionNo,
        headCounts: this.travelerInfo.userIdList.length,
        packageCodes: this.packageCodes,
      };
      consumer_journey_calculatePrice(data).then(res => {
        this.priceData = res.datas;
      })
    },
    getTravelerInfo() {
      this.travelerInfo = this.form;
      this.packageCodes = this.$refs.aInsureChoice.get_date().id_list;

      //清空已选坐席--1
      this.trainSeatArr = [];
      //清空已选坐席--2
      this.trainSeatAll.forEach((item, index) => {
        item.forEach((CItem, CIndex) => {
          CItem.active = false;
        })
      });
      let data = {
        evectionNo: this.$route.query.evectionNo,
        headCounts: this.travelerInfo.userIdList.length,
        packageCodes: this.packageCodes,
      };
      consumer_journey_calculatePrice(data).then(res => {
        this.priceData = res.datas;
      })
    },
    getTrainsData() {
      let userData = this.$route.query;
      let data = {
        evectionNo: userData.evectionNo,
      };
      consumer_trains_order_getTrainOrderDeailByEvectionNo(data).then(res => {
        // 获取火车票行程
        this.trainList = res.datas;
        if (res.datas.numberOfSeats == 3) {
          this.trainSeatAll = this.businessSeatArr;
        } else if (res.datas.numberOfSeats == 4) {
          this.trainSeatAll = this.firstSeatArr;
        } else if (res.datas.numberOfSeats == 5) {
          this.trainSeatAll = this.secondSeatArr;
        }
        let dataAll = {
          fromStationCode: this.$route.query.fromStationCode,
          toStationCode: this.$route.query.toStationCode,
          trainCode: this.trainList.trainNo,
          trainDate: this.trainList.startDate
        };
        consumer_trains_common_queryRailwayTimetable(dataAll).then(res => {
          this.trainListAll = res.results;
        })
      });

    },
    showRulePopup() {
      this.orderRuleDialogVisible = true;
    }
  }
}
