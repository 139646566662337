// import getDepartmentUsers from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
import allList from "@/lib/data-service/haolv-default/consumer_web_costCenter_allList";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
export default {
    name: 'CostAttributionList',
    data () {
        return {
            showPop: false,
            activeName: '1',
            cascadeList: [],
            treeDataSource: [],
            allCostList: [], // 成本列表
        }
    },
    props: {
        feeType: { // 费用归属类型：1.组织架构，2.成本中心
            type: Number,
            default: 1
        },
        feeName: {
           type: String,
           default: ''
        },
        feeId: {
          // type: Number,
          default: ''
        },
    },
    components: {
        radioItem: () => import('./radioItem/index.vue')
    },
    created () {},
    async mounted () {
        document.addEventListener('click', this._clickReserveWrapper)
        let currentFeeType = this.feeType
        this.activeName = currentFeeType.toString()
        await this._getDeptList()
        this.treeDataSource = this._initTreeData(this.cascadeList)
        await this._allList()
        if (this.feeId === '') {
            return
        }
        if (this.feeType === 1) {
            this._toSelectRadio({deptId: this.feeId, deptName: this.feeName})
        } else {
            this._getActive({id: this.feeId, centerName: this.feeName})
        }
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
        document.removeEventListener('click', this._clickReserveWrapper)
    },
    watch: {
        activeName(newVal) {
            console.log(newVal)
            // this.currentFeeType = parseInt(newVal)
            // this.currentFeeType = parseInt(activeName)
        },
        feeType(newVal) {
            let activeName = newVal
            this.activeName = activeName.toString()
        },
        /*currentFeeType(newVal) {
            console.log('b', newVal)
        },*/
    },
    computed: {
        currentFeeType: {
            get () {
                return this.feeType
            },
            set (val) {
                this.$emit('update:feeType', val)
            }
        },
        currentFeeName: {
            get () {
                return this.feeName
            },
            set (val) {
                this.$emit('update:feeName', val)
            }
        },
        currentFeeId: {
            get () {
                return this.feeId
            },
            set (val) {
                this.$emit('update:feeId', val)
            }
        }
    },
    filters: {},
    methods: {
        _toggleShowPop() {
            this.showPop = !this.showPop
            if (this.showPop && this.feeType === 1) {
                this._toSelectRadio({deptId: this.feeId, deptName: this.feeName})
            }
            if (this.showPop && this.feeType === 2) {
                this._getActive({id: this.feeId, centerName: this.feeName})
            }
        },
        _clickReserveWrapper(e) {
            if (!!this.$refs.costAttributionList.contains(e.target)) return
            this.showPop = false
        },
        _handleClick() {

        },
        async _getDeptList() {
            let [err, res] = await awaitWrap(consumer_department_getDepartmentList())
            if (err) {
                this.cascadeList = []
                return false
            }
            this.cascadeList = res.datas.departmentListResults ? res.datas.departmentListResults : []
            let item = {
                childrenList: null,
                deptId: -1,
                deptLevel: 1,
                deptName: "各自所在部门",
                deptStatus: 1,
                parentId: 0,
                strength: 1,
                userId: null,
                userName: ""
            }
            this.cascadeList.unshift(item)
            return true
        },
        _initTreeData(list) {
            // 这里一定要转化，要不然他们的值监听不到变化
            let tempData = JSON.parse(JSON.stringify(list))
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false // 是否展开
                    m.check = false
                    m.indeterminate = false
                    // m.children = m.children || []
                    m.level = level
                    m.bLeft = level * 15
                    m.label = m.name
                    m.value = m.deptId
                    m.children = m.childrenList
                    delete m.childrenList
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            }
            reduceDataFunc(tempData, 1)
            return tempData
        },
        async _allList() {
            let res = await allList()
            let allCostList = res.datas
            allCostList.forEach(value => {
                value.check = false
            })
            this.allCostList = allCostList;
        },
        _getActive(item) {
            this.allCostList.forEach(value => {
                if (value.id === item.id) {
                    value.check = true
                } else {
                    value.check = false
                }
            })
            // 清除部门的选中
            let childrenAllClean = function (list) {
                list.forEach(value => {
                    value.check = false
                    if (value.children && value.children.length > 0) {
                        childrenAllClean(value.children)
                    }
                })
            }
            childrenAllClean(this.treeDataSource)

            this.currentFeeName = item.centerName
            this.currentFeeId = item.id
            this.currentFeeType = parseInt(this.activeName)
        },
        _handlerExpand(val) {
            val.isExpand = !val.isExpand
        },
        _toSelectRadio(val) {
            // this.inputActiveList = [val]
            let childrenAllChange = function (list, item) {
                list.forEach(value => {
                    if (item.deptId === value.deptId) {
                        value.check = true
                    } else {
                        value.check = false
                    }
                    if (value.children && value.children.length > 0) {
                        childrenAllChange(value.children, item)
                    }
                })
            }
            childrenAllChange(this.treeDataSource, val)
            console.log('aaaaaa', this.treeDataSource)
            this.allCostList.forEach(value => {
                value.check = false
            })
            this.currentFeeName = val.deptName
            this.currentFeeId = val.deptId
            this.currentFeeType = parseInt(this.activeName)
            this.$nextTick(() => {
                this.$emit('toSelect', val)
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/